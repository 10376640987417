.wrapper {
    margin: auto;
    width: 396px;
    padding-top:30px;
}

img.brick_square {
    height: 200px;
    aspect-ratio: 1;
}

div.brick_square {
    grid-row-end: span 60;
    grid-column: span 2;
}

img.brick_landscape {
    height: 130px;
    aspect-ratio: 16/10;
}

div.brick_landscape {
    grid-row-end: span 40;
    grid-column: span 2;
}

img.brick_portrait {
    height: 130px;
    aspect-ratio: 4/5;
}

div.brick_portrait {
    grid-row-end: span 40;
    grid-column: span 1;
}

.nav {
    font-size:10px;
}

.nav_link {
    text-decoration: none;    
}

.nav_link:hover {
    text-decoration: underline;
}

.galleryItemWrapperO {
    width: 300px;
    grid-row-end: span 115;
    grid-column: span 4;
}

.galleryItemWrapperI {
    height: 300px;
}

.galleryTitle {
    font-size: 20px;
    text-align: center;
    margin-top:2px;
    margin-bottom:2px;
    text-decoration: none;
}

a, a:visited, a:hover, a:active {
    color: inherit;
}

.sticky-header {
    background-color: #444;
    color: #fff;
    width:100%;
    position: fixed;  /*CSS property which makes nav sticky on top*/
    top: 0;
    left: 0;
    margin: 0;
    padding: 0px 8px;
    text-align:left;
    letter-spacing: 3px;
    height: 30px;
    z-Index: 2;
}

.footer {
    border-top: 1px solid rgba(255,255,255,.25);
    text-align: center;
    padding:4px;
    margin: 8px auto;
    color:#eee;
    width: 90%;
}

.ant-icon {
    font-size: 20px;
}

@media only screen and (min-width : 1000px) {
    .wrapper {
        width: 1200px;
        margin-bottom: 20px;
    }

    .masonry {
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(240px,4fr));
    }

    img.brick_square {
        height: 600px;
    }
 
    img.brick_landscape {
        height: 390px;
    }

    img.brick_portrait {
        height: 390px;
    }

    span {
        font-size: 20px;
    }

    .nav_title {
        font-size: 24px;
        padding: 0;
        margin: 0;
    }

    .masonry-item-edit:hover:after {
        font-size: 30px;
    }
      
    .masonry-item-edit:after {
        font-size: 60px;
    }

    .nav {
        font-size:36px;
        margin: 0;
        padding: 0;
    }

    .galleryItemWrapperO {
        width: 580px;
        grid-row-end: span 65;
        grid-column: span 2;
    }

    .galleryItemWrapperI {
        height: 580px;
    }

    .galleryTitle {
        font-size: 30px;
        text-align: center;
        margin-top:4px;
        margin-bottom:4px;
    }

    .sticky-header {
        padding: 4px 8px;
        height: 28px;
    }

    .wrapper {
        padding-top:36px;
    }

    .footer {
        font-size: 20px;
        padding:16px;
        padding-top: 30px;
    }

    .ant-icon {
        font-size: 24px;
    }
}
