:root {
  counter-reset: masonry;
}

body {
  background-color: #222;
  color: #eee;
}

.masonry {
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(auto-fill, minmax(80px,4fr)); /*240*/
  grid-auto-rows: 0;
  /*justify-items: center;*/
}

.masonry-item {
  border-radius: 5px;
}

.masonry-item {
   /*background-color: #eee;*/
   border-radius: 5px;
   overflow: hidden;
}

.masonry-item,
.masonry-item img {
   position: relative;
   object-fit: cover;
}

.masonry-item div,
.masonry-item img {
  position: absolute;
  left: 0;
  top: 0;
}

.masonry-item:after {
  position: absolute;
  top: 0;
  left: 0; 
  height: 100%;
  width: 100%;
  display: flex;
}

.masonry-item-edit:hover:after {
  font-size: 10px;
}

.masonry-item-edit:after {
  font-weight: bold;
  font-size: 20px;
  content: counter(masonry);
  counter-increment: masonry;  
  color: white;
  justify-content: center; 
  align-items: center;
  transition: all .1s ease-in;
}

.masonry-item-edit-selected:after {
  background-color: rgba(64, 64, 64, .75);
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.9);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#lightbox-wrapper {
  margin: auto;
}

#lightbox-img {
  max-width:100%;
  max-height:100%;
  display: block;
}

html, body {
  font-family: sans-serif;
}

span {
  font-size: 10px;
}

.nav_title {
  font-family: sans-serif;
  font-size: 16px;
  margin: 6px 0px;
}

img.loading {
  background: transparent url("assets/loading.gif") no-repeat scroll center center;
  background-size: 150px;
}
